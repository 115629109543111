import { FC, ReactNode } from 'react';

const Index: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <div className="body-container">
      <div className="h-wysiwyg-html" data-scheme="light">
        {children}
      </div>
    </div>
  );
};

export default Index;
