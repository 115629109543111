import { FC } from 'react';

// components
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';
import QtCard from 'modules/common/components/QtCard';
import OrganizationChart from './OrganizationChart';

// services
import { useGetUserOrganizationQuery } from 'api';

const failedToLoadMsg: string = `Oops, failed loading the organization chart. Please try again.`;

const Index: FC = () => {
  const {
    data: organization,
    isLoading,
    error,
  } = useGetUserOrganizationQuery({});

  if (isLoading) return <BouncingDotsLoader />;

  if (error) return <FailedToLoad message={failedToLoadMsg} />;

  if (organization?.data) {
    return (
      <div className="c-vendor-bills__organization__chart">
        <QtCard component={<OrganizationChart orgData={organization.data} />} />
      </div>
    );
  } else return <></>;
};

export default Index;
