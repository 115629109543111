import { FC, lazy, Suspense, useState } from 'react';

// API
import { useActOnBehalfMutation, useGetUserInfoQuery } from 'api';

// Components
import Loader from 'modules/common/components/BouncingDotsLoader';
import Notification from 'modules/common/components/Notification';
import VendorBill from './VendorBill';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';

// Lazy load components
function withSuspense<P>(Component: React.ComponentType & any) {
  return function WithSuspense(props: P) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
const OrganizationChart = withSuspense(
  lazy(() => import('./OrganizationChart'))
);
const VendorBillsHistory = withSuspense(
  lazy(() => import('./VendorBillsHistory'))
);

interface AlertProps {
  behalfName: string;
}

const ActOnBehalfAlert: FC<AlertProps> = ({ behalfName }) => {
  const [actOnBehalf] = useActOnBehalfMutation();

  return (
    <div className="c-box c-box--light mb">
      <div>
        <RemoveRedEyeOutlinedIcon fontSize="large" />
        {`You're acting on behalf of ${behalfName}`}.
      </div>
      <button
        className="c-button c-button--lighten"
        onClick={() => {
          actOnBehalf({ type: 'exit' });
        }}
      >
        Exit
      </button>
    </div>
  );
};

const Index: FC = () => {
  const { data: userinfo } = useGetUserInfoQuery({});
  const [isReviewBillsRender, setIsReviewBillsRender] = useState(false);

  const welcomeText = userinfo
    ? ` Hi, ${userinfo.name}! On this page you can find a list of vendor bills/credits waiting for your actions.`
    : null;

  return (
    <>
      {userinfo && userinfo.behalfName ? (
        <ActOnBehalfAlert
          behalfName={userinfo.behalfName}
        />
      ) : (
        <Notification content={welcomeText} />
      )}

      {true && (
        <>
          <div className="c-vendor-bills">
            <VendorBill
              type="review"
              setIsReviewBillsRender={setIsReviewBillsRender}
            />
            {/* This trick to prevent 2 same API running same time caused caching data overriding each other */}
            {isReviewBillsRender ? (
              <VendorBill type="approval" />
            ) : (
              <BouncingDotsLoader />
            )}
          </div>

          <div className="c-vendor-bills">
            <div className="c-vendor-bills__history">
              <VendorBillsHistory />
            </div>

            <OrganizationChart />
          </div>
        </>
      )}
    </>
  );
};

export default Index;
