import { ReactElement } from 'react';
import { isMobile } from 'react-device-detect';

const getBillRecordTypeElement = (
  billRecordType: string,
  billRecordTypeText: string
) => {
  let billRecordTypeElement: ReactElement;

  if (billRecordType === 'vendorcredit') {
    billRecordTypeElement = (
      <>
        <div className="b-bill-table__type b-bill-table__type__vendorcredit">
          {isMobile ? 'C' : billRecordTypeText}
        </div>
      </>
    );
  }

  // Default, vendor bill
  else {
    billRecordTypeElement = (
      <>
        <div className="b-bill-table__type b-bill-table__type__vendorbill">
          {isMobile ? 'B' : billRecordTypeText}
        </div>
      </>
    );
  }

  return billRecordTypeElement;
};

export default getBillRecordTypeElement;
