import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import configs from 'configs';

// States
import { useDispatch } from 'react-redux';

import { appApi, useUpdateBillMutation } from 'api';

// Components
import ButtonLoading from 'modules/common/components/ButtonLoading';

// Styles
import getToastPopup from 'modules/common/components/ToastPopup';
import './billActions.scss';

// Utils
import { scrollToTop } from 'modules/common/utils';

interface Props {
  billId: string;
}

const Index: FC<Props> = ({ billId }) => {
  const dispatch = useDispatch();

  const { billType, billRecordType } = useParams<
    keyof BillDetailParams
  >() as BillDetailParams;

  // Scoped component state for bill action
  const [files, setFiles] = useState<FileList | null>(null);
  //custrecord_gs_status: 1 - Approve / 2 - Reject. Default: 1
  const [newBillStatus, setNewBillStatus] = useState<string>('1');
  const [comment, setComment] = useState<string | null>(null);
  const [updateBill, billUpdateResponse] = useUpdateBillMutation();
  const [fileValidationMessage, setFileValidationMessage] = useState<{
    text: string | null;
    type: string | null;
  }>({
    text: null,
    type: null,
  });

  // Reference Elements
  const inputFile = useRef<HTMLInputElement | null>(null);
  const actionValue = useRef<HTMLSelectElement | null>(null);
  const commentArea = useRef<HTMLTextAreaElement | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement> | any) =>
    validateAndUploadFile(e.target.files);

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setNewBillStatus(e.target.value);
  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setComment(e.target.value);

  const onButtonClick = () => {
    const instance = inputFile.current;
    if (instance) instance.click();
  };

  const onSubmitAction = () => {
    let formData = new FormData();

    formData.append('newStatus', newBillStatus);
    if (comment) formData.append('comment', comment);
    // if (files) formData.append('file', files as Blob)
    // Iterate over all selected files
    if (files)
      Array.from(files).forEach((file) => {
        formData.append('file', file);
      });

    const data = {
      billType,
      billId,
      billRecordType,
      formData,
    };

    updateBill(data)
      .unwrap()
      .then(() => {
        // Manually invalidate tags
        if (comment) {
          dispatch(
            appApi.util.invalidateTags([
              { type: 'QtVendor', id: 'QtVendorComments' },
            ])
          );
        }

        if (files) {
          dispatch(
            appApi.util.invalidateTags([
              { type: 'QtVendor', id: 'QtVendorAttachments' },
            ])
          );
        }

        // Manatory refetch APIs
        dispatch(
          appApi.util.invalidateTags([
            { type: 'QtVendor', id: 'QtVendorData' },
            { type: 'QtVendor', id: 'QtVendorNextBills' },
          ])
        );

        // This hack to avoid re-fetching approval status too soon to avoid netsuite returns old data..
        setTimeout(() => {
          dispatch(
            appApi.util.invalidateTags([
              { type: 'QtVendor', id: 'QtVendorApprovalFlow' },
            ])
          );
        }, 1000);

        // Navigate to top
        scrollToTop();
        // Toast popup with success message
        getToastPopup('success', `Vendor bill status was updated.`);
      })
      .catch((error: any) => {
        getToastPopup(
          'error',
          `There was an error while updating vendor bill status: ${error.data.message}. Please try again.`
        );
      });
  };

  const validateAndUploadFile = async (files: FileList) => {
    const fileNames = [];

    // check if the provided count prop is less than uploaded count of files
    if (
      configs.maxUploadFileCount &&
      configs.maxUploadFileCount < files.length
    ) {
      setFileValidationMessage({
        text: `Sorry, only ${configs.maxUploadFileCount} file${
          configs.maxUploadFileCount !== 1 ? 's' : ''
        } can be uploaded at a time.`,
        type: 'error',
      });
      setFiles(null);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      // check if some uploaded file is not in one of the allowed formats
      if (
        configs.acceptedExtensions &&
        !configs.acceptedExtensions.some(
          (format) => files[i]?.name.toLowerCase().split('.').pop() === format
        )
      ) {
        setFileValidationMessage({
          text: `Sorry, file ${files[i].name} is not accepted.`,
          type: 'error',
        });
        setFiles(null);
        return;
      }

      if (files[i] && files[i].size > configs.maxFileSizeMB * 1024 * 1024) {
        setFileValidationMessage({
          text: `${files[i].name} exceeds size limit. Only ${configs.maxFileSizeMB} MB per file`,
          type: 'error',
        });
        setFiles(null);
        return;
      }

      // Filenames for display
      fileNames.push(files[i].name);
    }

    // Validation done, set files
    if (files.length > 0) {
      setFiles(files);
      setFileValidationMessage({
        text: `Selected files: ${fileNames.join(', ')}`,
        type: 'success',
      });
    }
  };

  const uploadInstructions = <>{configs.acceptedExtensions.join(', ')}</>;

  return (
    <div className="b-bill-action">
      <h4>Actions</h4>

      <div className="c-form">
        <fieldset>
          <label className="c-form-label--required">Action</label>
          <select
            className="c-form-select"
            ref={actionValue}
            onChange={onSelectChange}
            required
          >
            <option value={1}>
              {billType === 'review' ? 'Review' : 'Approve'}
            </option>
            <option value={2}>Reject</option>
          </select>
        </fieldset>

        <fieldset>
          <label>Comment</label>
          <textarea
            placeholder={
              '\u2022 Description of the expense\n\u2022 Project Code/Name (If applicable)\n\u2022 Cost Center and Business Unit'
            }
            rows={3}
            cols={50}
            className="c-form-input-field c-form-textarea-field"
            ref={commentArea}
            onChange={onCommentChange}
          />
        </fieldset>

        <fieldset>
          <label>Attachments</label>

          <div
            className={`b-file-upload b-file-upload__${fileValidationMessage.type}`}
            onClick={onButtonClick}
          >
            <img src="/assets/upload.svg" alt="upload-deployment" />
            <p>
              {fileValidationMessage.text
                ? fileValidationMessage.text
                : 'Browse file'}
            </p>
            <input
              type="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={onFileChange}
              multiple
            />
          </div>
          <small
            className={`c-upload-instructions ${
              fileValidationMessage.type === 'error'
                ? 'c-upload-instructions__highlight'
                : ''
            }`}
          >
            * Max upload: {configs.maxUploadFileCount} files, max{' '}
            {configs.maxFileSizeMB} MB per file. Allowed extensions:{' '}
            {uploadInstructions}. It's recommended to bundle multiple files into
            one ZIP file to upload
          </small>
        </fieldset>

        {billUpdateResponse.isLoading ? (
          <ButtonLoading />
        ) : (
          <button className="c-button" onClick={onSubmitAction}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default Index;
