import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import moduleStyles from 'scss/styles.module.scss'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} 
        placement="bottom" 
        enterTouchDelay={0}
        // Default timeout to close tooltip
        leaveTouchDelay={10000}
    />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: moduleStyles.white,
        color: moduleStyles.black,
        border: `1px solid ${moduleStyles.lightGrey}`,
        fontFamily: moduleStyles.fontFamily,
        margin: `${moduleStyles.marginPopper} !important`,
        maxWidth: 1000,

        fontSize: `${moduleStyles.mdTooltipFontSize} !important`,
        '@media (max-width:480px)': {
            fontSize: `${moduleStyles.smTooltipFontSize} !important`,
            margin: `${moduleStyles.marginPopperMobile} !important`
        }
    }
}))

export default HtmlTooltip