import { useGetUserInfoQuery } from 'api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const PostLoginPage = () => {
  const navigate = useNavigate();
  const { data: userinfo } = useGetUserInfoQuery({});

  useEffect(() => {
    if (userinfo) {
      localStorage.setItem('qt-bill-authstate', 'true');
      navigate('/');
    }
  }, [navigate, userinfo]);

  return (
    <div>Authentication in progress...</div>
  );
};

export default PostLoginPage;
