import { getApiUrl } from 'modules/common/utils';
import { useEffect } from 'react';

export const PostLogout = () => {
  useEffect(() => {
    localStorage.removeItem('qt-bill-authstate');
    window.location.assign(getApiUrl(`auth/signout`));
  }, []);

  return <div>Loading...</div>;
};

export default PostLogout;
