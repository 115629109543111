import { FC } from 'react';
import './billData.scss';

import { useGetBillAttachmentsQuery } from 'api';

// Components
import ApprovalFlow from 'modules/app/components/ApprovalFlow';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';

interface Props {
  billData: IBillDetails;
}

const convertDateTime = (date: string): string => {
  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
  if (date) return new Date(date).toLocaleDateString(undefined, options);
  else return '';
};

const Index: FC<Props> = ({ billData }) => {
  const bill = billData.data;

  const {
    data: billAttachments,
    isLoading,
    isFetching,
    error,
  } = useGetBillAttachmentsQuery(
    {
      billId: bill.id,
    }
  );

  let attachments;

  if (isLoading || isFetching) attachments = <BouncingDotsLoader />;
  else if (billAttachments && billAttachments.data.length) {
    attachments = billAttachments.data.map((attachment, id) => {
      return (
        <a
          className="c-link c-link--no-chevron c-link-item"
          key={id}
          href={attachment.url}
          target="_blank"
          rel="noreferrer"
        >
          {attachment.name}
        </a>
      );
    });
  } else if (error) {
    const failedToLoadAttachmentsMsg = `Oops, failed loading the vendor bill data. Please try again.`;
    attachments = <FailedToLoad message={failedToLoadAttachmentsMsg} />;
  }

  return (
    <>
      <div className="b-bill-info__data">
        <div className="b-bill-info__data__table">
          <ApprovalFlow billId={bill.id} />

          <div className="b-bill-info__data__table__container">
            <hr />
            <div className="b-bill-info__data__table__item">
              <div className="b-bill-info__data__table--left">
                <div className="b-bill-info__data__table__heading">
                  Subsidiary
                </div>
                <div className="b-bill-info__data__table__content">
                  {bill.subsidiary.refName}
                </div>
              </div>

              <div className="b-bill-info__data__table--right">
                <div className="b-bill-info__data__table__heading">Vendor</div>
                <div className="b-bill-info__data__table__content">
                  {bill.entity.refName}
                </div>
              </div>
            </div>
            <div className="b-bill-info__data__table__item">
              <div className="b-bill-info__data__table--left">
                <div className="b-bill-info__data__table__heading">
                  Invoice number
                </div>
                <div className="b-bill-info__data__table__content">
                  {bill.tranId}
                </div>
              </div>

              <div className="b-bill-info__data__table--right">
                <div className="b-bill-info__data__table__heading">Total</div>
                <div className="b-bill-info__data__table__content">{`${bill.custbody_gs_inv_total_sum} ${bill.currency.refName}`}</div>
              </div>
            </div>

            <div className="b-bill-info__data__table__item">
              <div className="b-bill-info__data__table--left">
                <div className="b-bill-info__data__table__heading">Date</div>
                <div className="b-bill-info__data__table__content">
                  {convertDateTime(bill.tranDate)}
                </div>
              </div>

              <div className="b-bill-info__data__table--right">
                <div className="b-bill-info__data__table__heading">
                  Due Date
                </div>
                <div className="b-bill-info__data__table__content">
                  {convertDateTime(bill.dueDate)}
                </div>
              </div>
            </div>

            <hr />

            <div className="b-bill-info__data__table__item">
              {/* Usually this field is empty. Try to take more space for bill memo */}
              {bill.custbody_gs_buyer_reference && (
                <div className="b-bill-info__data__table--left">
                  <div className="b-bill-info__data__table__heading">
                    Buyer Reference
                  </div>
                  <div className="b-bill-info__data__table__content">
                    {bill.custbody_gs_buyer_reference}
                  </div>
                </div>
              )}

              <div className="b-bill-info__data__table--right">
                <div className="b-bill-info__data__table__heading">
                  Bill Memo
                </div>
                <div className="b-bill-info__data__table__content">
                  {bill.memo}
                </div>
              </div>
            </div>

            <div className="b-bill-info__data__table__item">
              {/* Usually this field is empty. */}
              {bill.custbody_gs_internal_memo && (
                <div className="b-bill-info__data__table--left">
                  <div className="b-bill-info__data__table__heading">
                    Internal Memo
                  </div>
                  <div className="b-bill-info__data__table__content">
                    {bill.custbody_gs_internal_memo}
                  </div>
                </div>
              )}
            </div>

            <hr />

            <div className="b-bill-info__data__table__item">
              <div className="b-bill-info__data__table--left">
                <div className="b-bill-info__data__table__heading">
                  Attachments
                </div>
                <div className="b-bill-info__data__table__content">
                  {attachments ? attachments : <>No attachments</>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
