import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import BodyContainer from 'modules/app/layout/BodyContainer';
import Navbar from 'modules/app/layout/Navbar';
import { ToastContainer } from 'react-toastify';
import { AppRoutes } from './routes';

// Global style: Qt Design System
import 'react-toastify/dist/ReactToastify.css';
import './scss/styles.scss';

const App: FC = () => {
  // Page transition style
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage('fadeOut');
  }, [location, displayLocation]);

  return (
    <>
      <Navbar />
      <BodyContainer>
        <div
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === 'fadeOut') {
              setTransitionStage('fadeIn');
              setDisplayLocation(location);
            }
          }}
        >
          {/* Toast containter */}
          <ToastContainer />

          <AppRoutes />
        </div>
      </BodyContainer>
    </>
  );
};

export default App;
