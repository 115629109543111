import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPagination {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
}

const initialState: IPagination = {
  currentPage: 1,
  itemsPerPage: 5,
  totalPages: 1,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setTotalPages(state: IPagination, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    setCurrentPage(state: IPagination, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
});

export const paginationActions = paginationSlice.actions;
export default paginationSlice;
