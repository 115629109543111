import { useGetUserInfoQuery } from 'api';
import { FC, Fragment } from 'react';

// components
import Tooltip from 'modules/common/components/Tooltip';
import BillTooltip from './BillTooltip';

import './orgChart.scss';

interface Props {
  orgData: IEmployee[];
}

const Index: FC<Props> = ({ orgData }) => {
  const { data: userinfo } = useGetUserInfoQuery({});

  return (
    <Fragment>
      <h6>Your organization</h6>

      <div className="c-org__container">
        <div className="c-org__parent">
          <div className="c-org__box">
            <div className="c-org__box__item">
              <div className="c-org__box__item__name--manager">
                {userinfo?.name}
              </div>
            </div>
          </div>
        </div>
        <div className="c-org__children">
          <div className="c-org__box">
            {orgData.map((e, i) => {
              return (
                <Fragment key={i}>
                  <div className="c-org__box__item c-org__box__item__child">
                    <Tooltip title={<BillTooltip employee={e} />} key={i}>
                      <div className="c-org__box__item__name">{e.fullname}</div>
                    </Tooltip>
                    <small>{e.jobtitle}</small>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;
