import { useGetUserInfoQuery } from 'api';
import { isMobile } from 'react-device-detect';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { checkAuthStatus } from 'modules/common/utils';

import Main from 'modules/app/components';
import OrganizationChart from 'modules/app/components/OrganizationChart';
import VendorBillDetails from 'modules/app/components/VendorBillDetails';
import GenericError from 'modules/common/components/GenericError';
import PostLogout from 'modules/pages//PostLogout';
import LoginPage from 'modules/pages/LoginPage';
import PostLogin from 'modules/pages/PostLogin';
import App from './App';

function PrivateRoute({ children }: { children: any }) {
  const location = useLocation();

  // check for auth here
  if (!checkAuthStatus()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to="/login" />;
  }

  return children;
}

export const IndexRoutes = () => {

  return (
    <Routes>
      <Route element={<LoginPage />} path="/login" />
      <Route element={<PostLogin />} path="/postlogin" />
      <Route element={<PostLogout />} path="/postlogout" />
      <Route
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
        path="/*"
      />
      <Route element={checkAuthStatus() ? <Navigate to="/" /> : <Navigate to="login" />} path="*" />
    </Routes>
  );
};

export const AppRoutes = () => {
  const { data: userinfo } = useGetUserInfoQuery({});

  return (
    <Routes>
      {userinfo?.email && (
        <>
          <Route path="/" element={<Main />} />
            <Route
              path="/:billRecordType/:billId/:billType"
              element={<VendorBillDetails />}
            />
            <Route path="*" element={<GenericError />} />

            {/* Mobile route */}
            {isMobile && (
              <Route path="/organisation" element={<OrganizationChart />} />
            )}
        </>
      )}
    </Routes>
  );
};