import { ErrorBoundary, init } from '@sentry/react';
import configs from 'configs';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IndexRoutes } from './routes';

// Redux
import { Provider } from 'react-redux';
import store from './stores';

if (configs.sentryDns) {
  init({
    dsn: configs.sentryDns,
    environment: configs.env,
  });
} else console.warn('Sentry frontend - sentryDns is not available');

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <BrowserRouter>
          <IndexRoutes />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
