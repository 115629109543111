import { FC, useState, useEffect } from 'react'

// MUI
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// Components
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader'

interface Props {
    content: string | null
}

const Index: FC<Props> = ({
    content
}) => {
    const [displayInstruction, setDisplayInstruction] = useState<boolean>(false)

    useEffect(() => {
        const userDisplayInstruction = localStorage.getItem('display-instruction')
        if (!userDisplayInstruction) {
            setDisplayInstruction(true)
            localStorage.setItem('display-instruction', 'true')
        }

        if (userDisplayInstruction && JSON.parse(userDisplayInstruction) === true) {
            setDisplayInstruction(true)
        }
    }, [])

    const hideInstructionForever = () => {
        localStorage.setItem('display-instruction', 'false')
        setDisplayInstruction(false)
    }

    if (!content)
        return <BouncingDotsLoader />

    if (displayInstruction && content)
        return <div className='c-alert c-alert--success mb'>
            <button onClick={() => hideInstructionForever()} title="Got it !" />
            <div>
                <InfoOutlinedIcon fontSize='large' />
                {content ? content : <BouncingDotsLoader />}
            </div>
        </div>

    return <></>
}

export default Index