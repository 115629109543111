import { FC } from 'react';
import { isMobile } from 'react-device-detect';

import { useGetBillInvoiceImageQuery } from 'api';

// Components
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
  billId: string;
  imageId?: string;
}

interface HeadingProps {
  url: string;
}

const failedToLoadMsg: string = `Oops, failed loading the vendor invoice image. Please try again.`;

const getUrlByDevice = (url: string): string => {
  if (isMobile) return url + '#toolbar=0&navpanes=0&view=FitH&nav=off';
  else return url;
};

const HeadingInvoiceIMG: FC<HeadingProps> = ({ url }) => {
  return (
    <div className="b-bill-info__invoice__heading">
      <h4>Bill Invoice</h4>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <OpenInNewIcon />
      </a>
    </div>
  );
};

const Index: FC<Props> = ({ billId, imageId }) => {
  const {
    data: billData,
    isLoading,
    error,
    isFetching,
  } = useGetBillInvoiceImageQuery({
    billId,
    imageId,
  });

  if (isLoading || isFetching) return <BouncingDotsLoader />;
  else if (billData && billData.data.url) {
    return (
      <div className="b-bill-info__invoice">
        <HeadingInvoiceIMG url={billData.data.url} />
        <iframe title="invoiceImage" src={getUrlByDevice(billData.data.url)} />
      </div>
    );
  } else if (error) return <FailedToLoad message={failedToLoadMsg} />;
  else return null;
};

export default Index;
