import { every, filter, get, includes, isNull } from 'lodash';

const getBillFieldValue = (vendorbills: IHistoryBill, searchKey: string) =>
  get(vendorbills, searchKey, null);

const isBillValIncludedInFilter =
  (vendorbills: IHistoryBill) =>
  (searchValue: Array<string>, searchKey: string) => {
    const billFieldValue = getBillFieldValue(vendorbills, searchKey);
    return includes(searchValue, billFieldValue);
  };

const billIsValidWithFilter =
  (filters: IField | any) => (vendorbills: IHistoryBill) =>
    every(filters, isBillValIncludedInFilter(vendorbills));

const dateTimeDataFilter = (
  dates: Array<any>,
  vendorBills: IHistoryBill[]
): IHistoryBill[] => {
  const firstDate = dates[0];
  const lastDate = dates[1];

  if (firstDate && lastDate && +lastDate - +firstDate >= 0) {
    return vendorBills.filter((e) => {
      // First, convert trandate to normal DateTime format (trandate DD/MM/YYYY)
      const [day, month, year] = e.trandate.split('/');
      const thisElementDate = new Date(
        Number(year),
        Number(month) - 1,
        Number(day)
      );
      return (
        +thisElementDate - firstDate >= 0 && lastDate - +thisElementDate >= 0
      );
    });
  }

  return vendorBills;
};

const validDateRange = (dateRange: Array<any>): boolean => {
  return dateRange.every((d) => {
    return isNull(d) === false;
  });
};

export const searchFilters = (
  searchFields:
    | {
        vendorname: Array<string> | any;
      }
    | undefined,
  dateRange: Array<any>,
  vendorBills: IHistoryBill[]
) => {
  // If bold filters are provided
  if (searchFields?.vendorname.length > 0 && validDateRange(dateRange)) {
    return filter(
      dateTimeDataFilter(dateRange, vendorBills),
      billIsValidWithFilter(searchFields)
    );
  }

  // If only date filter is provided
  else if (searchFields?.vendorname.length === 0 || validDateRange(dateRange)) {
    return dateTimeDataFilter(dateRange, vendorBills);
  }

  // If only search field is provided
  else if (searchFields?.vendorname.length > 0 && !validDateRange(dateRange)) {
    return filter(vendorBills, billIsValidWithFilter(searchFields));
  } else {
    return vendorBills;
  }
};

export const orderBy = (
  vendorBills: IHistoryBill[] | any[],
  sortDirectionValue: string,
  sortDirection: string
) => {
  if (sortDirectionValue === 'total') {
    if (sortDirection === 'asc') {
      return [...vendorBills].sort((a, b) =>
        +a.total - +b.total > 0 ? 1 : -1
      );
    }

    if (sortDirection === 'desc') {
      return [...vendorBills].sort((a, b) =>
        +a.total - +b.total > 0 ? -1 : 1
      );
    }
  } else if (
    sortDirectionValue === 'trandate' ||
    sortDirectionValue === 'duedate'
  ) {
    const distantFuture = [31, 12, 9999];

    return [...vendorBills].sort((a, b) => {
      const [aDate, aMonth, aYear] = a[sortDirectionValue]
        ? a[sortDirectionValue].split('/')
        : distantFuture;
      const aDateTime = new Date(
        Number(aYear),
        Number(aMonth) - 1,
        Number(aDate)
      );

      const [bDate, bMonth, bYear] = b[sortDirectionValue]
        ? b[sortDirectionValue].split('/')
        : distantFuture;
      const bDateTime = new Date(
        Number(bYear),
        Number(bMonth) - 1,
        Number(bDate)
      );

      if (sortDirection === 'asc') {
        return +aDateTime - +bDateTime > 0 ? 1 : -1;
      } else {
        return +aDateTime - +bDateTime > 0 ? -1 : 1;
      }
    });
  } else {
    if (sortDirection === 'asc') {
      return [...vendorBills].sort((a, b) =>
        a[sortDirectionValue] > b[sortDirectionValue] ? 1 : -1
      );
    }

    if (sortDirection === 'desc') {
      return [...vendorBills].sort((a, b) =>
        a[sortDirectionValue] > b[sortDirectionValue] ? -1 : 1
      );
    }
  }

  return vendorBills;
};

// This function is for dateformat "/".
// Not sure why it's different from other metadata (e.g: bill details with "-")
// There's a diff data format between Netsuite staging and prod
export const getInternationalDateTime = (date: string): string => {
  if (!date) return '';

  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
  const [day, month, year] = date.split('/');
  const newDate = new Date(
    Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0)
  );

  return newDate.toLocaleDateString(undefined, options);
};

export const convertStatusBasedOnRole = (role: string, status: string) => {
  if (role === 'Reviewer' && status === 'Approved') return 'Reviewed';
  else return status;
};
