import { FC } from 'react'

interface Props {
    message: string
}

const Index: FC<Props> = ({
    message
}) => {
    return <p className="p-error">
        {message}
    </p>
}

export default Index