import { FC } from 'react'
import { Link } from 'react-router-dom'
import './notFound.scss'

interface Props {
    errorHeader?: string
    errorMessage?: string
}

const Index: FC<Props> = ({
    errorHeader,
    errorMessage
}) => {

    errorHeader = errorHeader ? errorHeader : `Oops, 404 not found`
    errorMessage = errorMessage ? errorMessage : `The page you are looking for wasn't found at this location.`

    return <>
        <div className="b-not-found">
            <div className="b-not-found-text">
                <h1>{errorHeader}</h1>
                <p className="large">{errorMessage}</p>
                <br />
                <Link className="c-button" to = '/'>{'<  Back to Home'}</Link>
            </div>

            <div className="b-not-found-img">
                <img src="https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=291&amp;name=designguru-ape.jpg"
                    alt="designguru-ape" width="291" style={{ width: '291px' }}
                    srcSet="https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=146&amp;name=designguru-ape.jpg 146w, https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=291&amp;name=designguru-ape.jpg 291w, https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=437&amp;name=designguru-ape.jpg 437w, https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=582&amp;name=designguru-ape.jpg 582w, https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=728&amp;name=designguru-ape.jpg 728w, https://www.qt.io/hs-fs/hubfs/_website/avatars/junk-avatars/designguru-ape.jpg?width=873&amp;name=designguru-ape.jpg 873w"
                    sizes="(max-width: 291px) 100vw, 291px"
                />
            </div>
        </div>
    </>
}

export default Index