import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBillHistoryStates {
  searchFields: {
    vendorname: Array<string> | any;
  };
  currentPageData: IHistoryBill[];
  dateRange: Array<any>;
  sortDirection: string | null;
  sortDirectionValue: string | null;
  defaultOptions: IField[];
}

const initialState: IBillHistoryStates = {
  searchFields: {
    vendorname: [],
  },
  currentPageData: [],
  dateRange: [null, null],
  sortDirection: null,
  sortDirectionValue: 'trandate',
  defaultOptions: [],
};

const billHistorySlice = createSlice({
  name: 'billHistory',
  initialState,
  reducers: {
    setSearchFields(
      state: IBillHistoryStates,
      action: PayloadAction<{
        vendorname: Array<string> | any;
      }>
    ) {
      state.searchFields = action.payload;
    },
    setCurrentPageData(
      state: IBillHistoryStates,
      action: PayloadAction<IHistoryBill[]>
    ) {
      state.currentPageData = action.payload;
    },
    setDateRange(state: IBillHistoryStates, action: PayloadAction<any[]>) {
      state.dateRange = action.payload;
    },
    setSortDirection(
      state: IBillHistoryStates,
      action: PayloadAction<string | null>
    ) {
      state.sortDirection = action.payload;
    },
    setSortDirectionValue(
      state: IBillHistoryStates,
      action: PayloadAction<string | null>
    ) {
      state.sortDirectionValue = action.payload;
    },
    setDefaultOptions(
      state: IBillHistoryStates,
      action: PayloadAction<IField[]>
    ) {
      state.defaultOptions = action.payload;
    },
  },
});

export const billHistoryActions = billHistorySlice.actions;
export default billHistorySlice;
