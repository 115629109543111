import { configureStore, Store } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import billHistoryReducer from 'stores/billHistory/billHistory.slice';
import paginationReducer from 'stores/pagination/pagination.slice';

// API
import { appApi } from 'api/';

const store: Store = configureStore({
  reducer: {
    vendorApi: appApi.reducer,
    pagination: paginationReducer.reducer,
    billHistory: billHistoryReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(appApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
