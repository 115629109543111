import { FC } from "react"
import './dotsLoader.scss'
const BouncingDotsLoader: FC = () => {
  return (
    <>
      <div className="c-bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default BouncingDotsLoader
