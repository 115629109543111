import configs from "configs";

export const scrollToTop = () =>
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const getApiUrl = (path?: string) => {
  return `${configs.apiUrl ?? ''}/api${
    path ? '/' + path : ''
  }`;
}

export const checkAuthStatus = () => {
  const status = localStorage.getItem('qt-bill-authstate');
  return status === 'true';
};
