import { FC } from 'react';

import { useGetBillCommentsQuery } from 'api';

import { convertStatusBasedOnRole, getInternationalDateTime } from 'helpers';
import sanitize from 'sanitize-html';

// Styles
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';
import './billComment.scss';
interface Props {
  billId: string;
}

const failedToLoadMsg: string = `Oops, failed while loading bill comment. You may be able to try again.`;

const getReviewalStatus = (status: string) => {
  if (status === 'Approved')
    return 'c-chat-message__text--status c-chat-message__text--status--approved';
  else if (status === 'Rejected')
    return 'c-chat-message__text--status c-chat-message__text--status--rejected';
  else return '';
};

const Index: FC<Props> = ({ billId }) => {

  const {
    data: comments,
    isLoading,
    isFetching,
    error,
  } = useGetBillCommentsQuery({
    billId
  });

  if (isLoading || isFetching)
    return (
      <div className="b-bill-info__invoice">
        <BouncingDotsLoader />
      </div>
    );

  if (error) return <FailedToLoad message={failedToLoadMsg} />;

  if (comments && comments.data.length) {
    return (
      <div className="b-bill b-bill-comment">
        <h4>Comments</h4>
        <div className="l-chatbox animated" data-animate="animated">
          <div className="l-chatbox__item">
            {comments.data.map((comment, index) => {
              const sanitizedComment = sanitize(comment.comment);

              return (
                <div className="c-chat-message" key={index}>
                  <div className="c-chat-message__icon" />
                  <div className="c-chat-message__text">
                    <div className="c-chat-message__text__heading">
                      <div className="c-chat-message__text__heading__name">
                        {comment.fullname}
                      </div>
                      <small className={getReviewalStatus(comment.status)}>
                        {convertStatusBasedOnRole(comment.role, comment.status)}
                      </small>
                    </div>
                    <small>
                      {comment.role} -{' '}
                      {getInternationalDateTime(comment.lastmodified)}
                    </small>
                    <p
                      dangerouslySetInnerHTML={{ __html: sanitizedComment }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default Index;
