import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useGetNextBillsQuery } from 'api';

// Components
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';

// Styles
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import './nextBills.scss';

const failedToLoadMsg: string = `Oops, failed loading the next bills. Please try again.`;

function getNextBill(array: string[], excludeValue: string) {
  const currentIndex = array.indexOf(excludeValue);

  // This case happens when user just approve the bill. Return to first item from list
  if (currentIndex === -1) {
    return array[0];
  }

  // Calculate the next index and wrap around to the beginning if needed
  const nextIndex = (currentIndex + 1) % array.length;

  // If next bill is the current bill, return nothing
  return nextIndex === 0 ? null : array[nextIndex];
}

const Index: FC = () => {
  const { data: nextBills, isLoading, error } = useGetNextBillsQuery({});

  if (isLoading) return <BouncingDotsLoader />;

  if (nextBills && nextBills.data.length) {
    // eslint-disable-next-line no-restricted-globals
    const nextBillPath = location
    // eslint-disable-next-line no-restricted-globals
      ? getNextBill(nextBills.data, location.pathname.substring(1))
      : null;

    if (nextBillPath) {
      return (
        <>
          <Link
            className="c-button c-button__nextbill"
            to={'../' + nextBillPath}
            title="Next action bill"
          >
            <ReceiptLongIcon />
            <NavigateNextIcon />
          </Link>
        </>
      );
    } else return <></>;
  }

  if (error) return <FailedToLoad message={failedToLoadMsg} />;
  else return <></>;
};

export default Index;
