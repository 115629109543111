import { FC } from 'react';

interface Props {
  billData: IBillDetails;
}

const Index: FC<Props> = ({ billData }) => {
  const BillPostingItems = () =>
    billData.data.expense.items.map((item, index) => {
      return (
        <div className="b-bill-table" key={index}>
          <div className="b-bill-table--more-space">{item.account.refName}</div>

          <div>{item.tax?.grossamt}</div>

          <div className="b-bill-table b-bill-table__taxname">
            {item.tax?.taxnamedisplay ? `VAT:${item.tax?.taxnamedisplay}` : ''}
          </div>

          <div className="b-bill-table--more-space">{item.tax?.tax1amt}</div>

          <div>{item.tax?.amount}</div>

          <div className="b-bill-table--more-space">
            {item.department?.refName ?? '-'}
          </div>
          <div className="b-bill-table--more-space">
            {(item.custcol_cseg_gs_project || item.customer?.refName) ?? '-'}
          </div>
          <div className="b-bill-table--more-space">{item.memo}</div>
          <div>{item.tax?.amortizstartdate ?? '-'}</div>
          <div>{item.tax?.amortizationenddate ?? '-'}</div>
        </div>
      );
    });

  if (billData.data.expense.totalResults === 0) return <></>;

  return (
    <>
      <h4>Bill postings</h4>
      <div className="b-bill-table b-bill-table__header b-bill-table__header__posting">
        <div className="b-bill-table--more-space">Account</div>
        <div>Gross Amount</div>
        <div>Tax Code</div>
        <div className="b-bill-table--more-space">Tax Amount</div>
        <div>Amount</div>
        <div className="b-bill-table--more-space">Department</div>
        <div className="b-bill-table--more-space">Customer/Project</div>
        <div className="b-bill-table--more-space">Memo</div>
        <div>Amort start</div>
        <div>Amort end</div>
      </div>

      {BillPostingItems()}
    </>
  );
};

export default Index;
