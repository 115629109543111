import { FC, useEffect, useRef } from 'react';

import { Link, useParams } from 'react-router-dom';

import { useGetBillDataQuery } from 'api';

// States

// components
import GenericError from 'modules/common/components/GenericError';
import BillActions from './BillActions';
import BillComments from './BillComments';
import BillData from './BillData';
import BillPostings from './BillPostings';
import BillSkeleton from './BillSkeleton';
import InvoiceImage from './InvoiceImage';
import NextBills from './NextBills';

// Styles
import autoAnimate from '@formkit/auto-animate';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import './billDetails.scss';

const getBillHeaderStatusStyle = (billStatus: string): string => {
  if (billStatus === 'Open' || billStatus === 'Paid In Full')
    return 'b-bill-header__status b-bill-header__status--approved';
  else if (billStatus === 'Cancelled' || billStatus === 'Rejected')
    return 'b-bill-header__status b-bill-header__status--rejected';
  else return 'b-bill-header__status';
};

const Index: FC = () => {
  const { billId, billRecordType, billType } = useParams<
    keyof BillDetailParams
  >() as BillDetailParams;

  // Effects transition style
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const {
    data: billData,
    isLoading,
    isFetching,
    error,
  } = useGetBillDataQuery({
    billRecordType,
    billId,
    billType,
  });

  if (isLoading || isFetching) return <BillSkeleton />;

  if (error) {
    console.log(error);
    // @ts-ignore
    if (error.status === 403)
      return (
        <GenericError
          errorHeader="403 Forbidden"
          errorMessage={`You're not allowed to view this bill.`}
        />
      );
    // @ts-ignore
    else if (error.status === 404) return <GenericError />;
    else {
      return (
        <GenericError
          errorHeader="Oops, there is an error."
          errorMessage={`Failed loading the bill data. Please try again.`}
        />
      );
    }
  }

  if (billData && billData.data) {
    return (
      <>
        <Link className="c-link c-link--no-chevron" to="/">
          {'< Back to Bill Dashboard'}
        </Link>

        <div className="b-bill-header">
          <ReceiptLongIcon />
          <div className="b-bill-header__recordType">
            {billData.data.recordType}
          </div>
          {billData.data.custbody_gs_inv_reference_text && (
            <div className="b-bill-header__reference">
              {billData.data.custbody_gs_inv_reference_text}
            </div>
          )}
          <div className="b-bill-header__vendor">
            {billData.data.entity.refName}
          </div>
          <div
            className={getBillHeaderStatusStyle(billData.data.status?.refName)}
          >
            {billData.data.status?.refName
              ? billData.data.status?.refName
              : 'N/A'}
          </div>

          <NextBills />
        </div>

        <div className="b-bill-info" ref={parent}>
          <InvoiceImage
            billId={billId}
            imageId={billData.data.custbody_gs_bill_image}
          />

          <BillData billData={billData} />

          <div className="b-bill-info--flex">
            {billData.data.actionable && (
              <BillActions billId={billData.data.id} />
            )}
            <BillComments billId={billId} />
          </div>
        </div>

        <div className="b-bill">
          <div className="b-bill-posting">
            <BillPostings billData={billData} />
          </div>
        </div>
      </>
    );
  } else return <></>;
};

export default Index;
