import { FC, useEffect } from 'react';

// States

// APIs
import { useGetVendorBillsByTypeQuery } from 'api';

// Components
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader';
import FailedToLoad from 'modules/common/components/FailToLoad';
import QtCard from 'modules/common/components/QtCard';
import VendorBillTable from './VendorBillTable';

import './vendorbills.scss';

const failedToLoadMsg: string = `Oops, failed loading the vendor bills. Please try again.`;

interface Props {
  type: string;
  setIsReviewBillsRender?: (state: boolean) => void;
}

const Index: FC<Props> = ({ type, setIsReviewBillsRender }) => {
  const {
    data: vendorbills,
    isFetching,
    isLoading,
    error,
  } = useGetVendorBillsByTypeQuery({ billType: type });

  useEffect(() => {
    if (vendorbills && setIsReviewBillsRender) setIsReviewBillsRender(true);
  }, [vendorbills, setIsReviewBillsRender]);
  const headingType = type === 'review' ? 'review' : 'approve';

  const VendorToReview = (
    <>
      <h6>Bills to {headingType}</h6>

      {isLoading || isFetching ? (
        <BouncingDotsLoader />
      ) : error ? (
        <FailedToLoad message={failedToLoadMsg} />
      ) : vendorbills ? (
        <VendorBillTable vendorbills={vendorbills} />
      ) : null}
    </>
  );

  return (
    <>
      <QtCard component={VendorToReview} />
    </>
  );
};

export default Index;
