import { toast } from 'react-toastify'

const toastSettings: any = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const getToastPopup = (toastType: string, message: string) => {
    if (toastType === 'warn') return toast.warn(message, toastSettings)
    if (toastType === 'success') return toast.success(message, toastSettings)
    if (toastType === 'error') return toast.error(message, toastSettings)
}

export default getToastPopup