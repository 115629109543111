import { FC, ReactNode } from 'react'

interface Props {
    component: ReactNode
}

const Index: FC<Props> = ({
    component
}) => {
    return <div className="c-box c-box--white mb">
        {component}
    </div>
}

export default Index