import development from './env/development';
import production from './env/production';
import staging from './env/staging';

type IConfig = {
  env: string;
  sentryDns: string | undefined;
  thisURL: string;
  acceptedExtensions: Array<string>;
  maxFileSizeMB: number;
  maxUploadFileCount: number;
  apiUrl: string;
};

const defaults = {
  env: process.env.REACT_APP_ENV || 'development',
  sentryDns: process.env.REACT_APP_SENTRY_URL || process.env.SENTRY_DNS,
  apiUrl: process.env.REACT_APP_API_URL!,

  acceptedExtensions: [
    // 'csv',
    'xls',
    'xlsx',
    // 'gz',
    // 'html',
    'jpg',
    'jpeg',
    'pdf',
    'txt',
    'png',
    // 'ppt',
    // 'pptx',
    // 'sms',
    // 'svg',
    'doc',
    'docx',
    'xml',
    'zip',
  ],
  maxFileSizeMB: 8,
  maxUploadFileCount: 5,
};

let config: IConfig;

switch (defaults.env) {
  case 'development':
    config = Object.assign(defaults, development);
    break;
  case 'staging':
    config = Object.assign(defaults, staging);
    break;
  case 'production':
    config = Object.assign(defaults, production);
    break;
  default:
    config = Object.assign(defaults, development);
    break;
}

export default config;
