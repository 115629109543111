import { getApiUrl } from 'modules/common/utils';
import { useEffect } from 'react';

export const LoginPage = () => {
  useEffect(() => {
    window.location.assign(getApiUrl(`auth/signin`));
  }, []);

  return <div>Loading...</div>;
};

export default LoginPage;
