import { FC } from 'react'

import BouncingDotsLoader from '../BouncingDotsLoader'

import './buttonLoading.scss'

const Index: FC = () => {
    return (
        <>
            <button className="c-button c-button--loading" disabled={true}>
                <BouncingDotsLoader />
            </button>
        </>
    )
}

export default Index