import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Box, Grid, Skeleton } from '@mui/material';
import { useActOnBehalfMutation, useGetUserOverviewBillsQuery } from 'api';

// Components
import FailedToLoad from 'modules/common/components/FailToLoad';

// Utils
import { scrollToTop } from 'modules/common/utils';

interface Props {
  employee: IEmployee;
}

const failedToLoadMsg: string = `Oops, failed while loading the vendor bills. You may be able to try again.`;

const LoadingSkeleton: FC = () => {
  return (
    <div className="c-tooltip__loading">
      <Grid item xs={12}>
        <Box>
          <Skeleton variant="rectangular" height={25} />
          <Box sx={{ pt: 1 }}>
            <Skeleton variant="rectangular" height={25} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Skeleton variant="rectangular" height={20} width={100} />
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

const Index: FC<Props> = ({ employee }) => {
  // React router
  const location = useLocation();
  const navigate = useNavigate();

  // State
  const [actOnBehalf] = useActOnBehalfMutation();

  const {
    data: billsOverview,
    isLoading,
    error,
  } = useGetUserOverviewBillsQuery({
    userId: employee.id,
  });

  const onActOnBehalf = (fullname: string) => {
    actOnBehalf({
      userId: employee.id,
      type: 'act',
      name: fullname,
    })
      .unwrap()
      .then(() => {
        // Return back to dashboard
        if (location.pathname !== '/') return navigate('/');
        scrollToTop();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  if (isLoading) return <LoadingSkeleton />;

  if (billsOverview && billsOverview.data) {
    return (
      <>
        <div className="c-tooltip__bills">
          <div>
            <ReceiptLongIcon />
            <p>Bills to review: {billsOverview.data.reviewalBills}</p>
          </div>
          <div>
            <ReceiptLongIcon />
            <p>Bills to approve: {billsOverview.data.approvalBills}</p>
          </div>
          <button className="c-link c-link--no-chevron" onClick={() => onActOnBehalf(employee.fullname)}>
            Act on behalf of {employee.fullname.split(' ')[0]}
          </button>
        </div>
      </>
    );
  }

  if (error) return <FailedToLoad message={failedToLoadMsg} />;
  else return <></>;
};

export default Index;
