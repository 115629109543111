import { useGetUserInfoQuery } from 'api';
import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';

const Index: FC = () => {
  const { data: userinfo } = useGetUserInfoQuery({});

  // Mobile: Click item to close navigation bar
  const hamburger = useRef<HTMLInputElement | null>(null);
  const onCloseNavigationBar = () => {
    const instance = hamburger.current;
    if (instance) instance.click();
  };

  return (
    <header className="b-header" data-float="true">
      <div className="b-header__container">
        <div className="b-header__primary-level">
          <div className="b-header__primary-level__left">
            <Link className="b-header__logo" to="/">
              <img src="/assets/qtgroup_black.svg" alt="Qt Logo" />
            </Link>
          </div>
          <div className="b-header__primary-level__navigation">
            <nav>
              <ul className="c-navigation-header">
                {/* Mobile navigation */}
                {userinfo && userinfo.isManager && (
                  <li role="menu" id="organisation">
                    <Link to="/organisation" onClick={onCloseNavigationBar}>
                      Your organisation
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="b-header__primary-level__hamburger">
            <div className="c-hamburger" ref={hamburger}></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Index;
