import { FC } from 'react';
import { isMobile } from 'react-device-detect';

import { Box, Grid, Skeleton } from '@mui/material';

const MobileSkeleton: FC = () => {
  return (
    <div>
      <Grid container wrap="nowrap" style={{ overflow: 'hidden' }}>
        <Box>
          {/* Back button */}
          <Skeleton variant="rectangular" height={'3vh'} width={'30vw'} />

          {/* Header */}
          <Grid container wrap="nowrap" sx={{ pt: 2 }}>
            <Box sx={{ width: '35vw', marginRight: 2 }}>
              <Skeleton variant="rectangular" width={'100%'} height={'5vh'} />
            </Box>
            <Box sx={{ width: '25vw', marginRight: 2 }}>
              <Skeleton variant="rectangular" width={'100%'} height={'5vh'} />
            </Box>
            <Box sx={{ width: '25vw' }}>
              <Skeleton variant="rectangular" width={'100%'} height={'5vh'} />
            </Box>
          </Grid>

          {/* Heading invoice img */}
          <Box sx={{ pt: 2 }}>
            <Skeleton variant="rectangular" height={'5vh'} width={'45vw'} />
          </Box>

          {/* Invoice img */}
          <Grid container wrap="nowrap" sx={{ pt: 2 }}>
            <Box sx={{ width: '100vw' }}>
              <Skeleton variant="rectangular" width={'100%'} height={'50vh'} />
            </Box>
          </Grid>

          {/* Bill Data */}
          <Grid container wrap="nowrap" sx={{ pt: 2 }}>
            <Box sx={{ width: '100vw' }}>
              <Skeleton variant="rectangular" width={'100%'} height={'50vh'} />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

const DesktopSkeleton: FC = () => {
  return (
    <div>
      <Grid container wrap="nowrap" style={{ overflow: 'hidden' }}>
        <Box>
          <Skeleton variant="rectangular" height={'3vh'} width={'15vw'} />
          <Box sx={{ pt: 2 }}>
            <Skeleton variant="rectangular" height={'6vh'} width={'45vw'} />
          </Box>

          <Grid container wrap="nowrap" sx={{ pt: 2 }}>
            <Box sx={{ width: '35vw', marginRight: 3 }}>
              <Skeleton variant="rectangular" width={'100%'} height={'70vh'} />
            </Box>

            <Box sx={{ width: '40vw', marginRight: 3 }}>
              <Skeleton variant="rectangular" width={'100%'} height={'70vh'} />
            </Box>

            <Box sx={{ width: '25vw' }}>
              <Skeleton variant="rectangular" width={'100%'} height={'70vh'} />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

const Index: FC = () => {
  if (isMobile) return <MobileSkeleton />;
  else return <DesktopSkeleton />;
};

export default Index;
