import { FC } from 'react';

import { useGetBillApprovalFlowQuery } from 'api';

// States

import { Box, Grid, Skeleton } from '@mui/material';
import './approvalFlow.scss';

// Components
import FailedToLoad from 'modules/common/components/FailToLoad';

interface Props {
  billId: string;
}

const failedToLoadMsg: string = `Oops, failed loading the bill approval flow. Please try again.`;

const LoadingSkeleton: FC = () => {
  return (
    <div className="c-tooltip__loading">
      <Grid item xs={12}>
        <Box>
          <Skeleton variant="rectangular" height={35} />
          <Box sx={{ pt: 2 }}>
            <Skeleton variant="rectangular" height={35} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Skeleton variant="rectangular" height={35} />
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

const getApprovalFlowStyle = (approvalValue: string): string => {
  if (approvalValue === '0') return 'c-stepper__item c-stepper__item--pending';
  else if (approvalValue === '1')
    return 'c-stepper__item c-stepper__item--approved';
  else if (approvalValue === '2')
    return 'c-stepper__item c-stepper__item--rejected';
  else return 'c-stepper__item';
};

const Index: FC<Props> = ({ billId }) => {
  const {
    data: approvalFlows,
    isLoading,
    isFetching,
    error,
  } = useGetBillApprovalFlowQuery({
    billId,
  });

  if (isLoading || isFetching) return <LoadingSkeleton />;

  if (approvalFlows && approvalFlows.data) {
    return (
      <>
        <div className="c-stepper__container">
          <ol className="c-stepper">
            {approvalFlows.data.map((flow, index) => {
              return (
                <li
                  className={getApprovalFlowStyle(flow.statusValue)}
                  key={index}
                >
                  <div className="c-stepper__title">{flow.fullname}</div>
                  <p className="c-stepper__desc">{flow.role}</p>
                </li>
              );
            })}
          </ol>
        </div>
      </>
    );
  }

  if (error) return <FailedToLoad message={failedToLoadMsg} />;
  else return <></>;
};

export default Index;
