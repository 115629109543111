import { FC } from 'react'
import { Link } from 'react-router-dom'
import { getInternationalDateTime } from 'helpers'
import getBillRecordTypeElement from 'modules/common/utils/getBillRecordType'
interface Props {
    vendorbills: IVendorBills
}

const VendorBillTable: FC<Props> = ({
    vendorbills,
}) => {

    const VendorItems = () => vendorbills.data.map((item: IVendorBill, index) => {
        return <div className="b-bill-table" key={index}>
            <div className="b-bill-table__billtype">{getBillRecordTypeElement(item.recordtype, item.recordtypetext)}</div>
            <Link className="c-link c-link--no-chevron b-bill-table__id" to={item.path}>{item?.id}</Link>
            <Link className="c-link c-link--no-chevron b-bill-table--double b-bill-table__vendorname" to={item.path}>{item?.vendorname}</Link>
            <div className="b-bill-table--more-space">{getInternationalDateTime(item?.trandate)}</div>
            <div className={item.expressapproval === 'T' ? 'b-bill-table--expressapproval b-bill-table--more-space' : 'b-bill-table--more-space b-bill-table__duedate'}>{getInternationalDateTime(item?.duedate)}</div>
            <div>{item?.total} <br/> {item?.currencyname}</div>
        </div>
    })

    if (vendorbills.data.length === 0) return <p className="p-heading">Nothing to show</p>

    return (
        <div className="mt-1">
            <div className="b-bill-table b-bill-table__header">
                <div className="b-bill-table__billtype">Type</div>
                <div className="b-bill-table__id">Number</div>
                <div className="b-bill-table--double">Vendor</div>
                <div className="b-bill-table--more-space">Date</div>
                <div className="b-bill-table--more-space">Due Date</div>
                <div>Amount</div>
            </div>

            {VendorItems()}
        </div>
    )
}

export default VendorBillTable